<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">Test</v-card-text>
    </v-col>
    <v-col cols="12">
      <div>
        <!-- <SelectBox
          label="Select CPT Code"
          selectStyleWidth="width: 100%"
          :value="selectedCptCode"
          :items="itemsCptCode"
          :multiple="false"
          @changeSelect="cptCode_selectEvent"
        /> -->
        <!-- <SelectBoxForTypeList :items="timeList" @updateSelect="updateSelect" /> -->

        <!-- <SelectBirthday value="19730417" @updateSelect="updateSelect" /> -->

        <!-- <SelectBoxPrimaryPhysician hospital_id="10001" selectedValue="" /> -->

        <!-- <SelectTypeWithEtc :items="visitTypeItems" /> -->

        <!-- <SelectBoxICDCode :value="icdCode" @updateValue="icdCodeUpdateValue" /> -->

        <!-- <AddressListItem :data="addressData" /> -->

        <!-- <Biometrics :selectList /> -->

        <!-- <CheckActiveLog :dataType="dataType" /> -->

        <!-- <CheckComps
          :isHorizontal="true"
          :items="ActiveLogData"
          :value="ActiveLogValue"
          @changeType="checkCompsChangeType"
        /> -->

        <!-- <CheckComps
          :items="ActiveTypeData"
          :value="ActiveTypeValue"
          @changeType="checkCompsChangeType"
        /> -->

        <!-- 값처리 미완성 -->
        <!-- <RadioComps
          :items="genderData"
          :prosSelected="genderValue"
          @changeType="radioCompsChangeType"
        /> -->

        <!-- <CheckComps
          :isHorizontal="true"
          :items="allergyTypeItems"
          :value="allergyTypeValue"
          @changeType="checkCompsChangeType"
        /> -->

        <!-- <CheckComps
          :isHorizontal="true"
          :items="serviceTypeItems"
          :value="serviceTypeValue"
          @changeType="checkCompsChangeType"
        /> -->

        <DateCompWithTime value="20240101" @complete="onChangeDateAndTime" />

        <!-- <InputName3Type :data="nameObj" /> -->

        <!-- <DialogInputFid ref="refDialogInputFid" /> -->

        <!-- <DuplCheckType
          checkInputType="string"
          checkType="mobile"
          :checkedValue="userData.checkedMobile"
          :value="userData.cell"
          :prosDuplicateChecked="userData.duplicateMobileChecked"
          :userData="userData"
        /> -->

        <!-- <DuplCheckType
          checkInputType="number"
          checkType="SSN"
          :checkedValue="userData.checkedSsn"
          :value="userData.ssn"
          :prosDuplicateChecked="userData.duplicateSSNChecked"
          :userData="userData"
        /> -->

        <!-- <DataTable
          ref="Datatable"
          :loading="loading"
          :loadingText="loadingText"
          :tableKey="'rowSeq'"
          :footerHide="false"
          :singleSelect="true"
          :showSelect="true"
          :columns="userInfoColumns"
          :inputData="deviceList"
        /> -->

        <!-- <SelectBoxClinic /> -->

        <!-- <TopTable 
          :btnIsShow="reqData.hospital_id === 0 ? false : true"
          :IsSwitchShow="reqData.hospital_id === 0 ? false : true"
          :xlsxBtnIsShow="false"
          btnLabel="NEW"
          :switchOn="viewActiveOnOff"
          switchOnLabel="Active"
          switchOffLabel="Block"
          :data="List"
          @clickXlsxBtn="xlsxSave"
          @clickEditorBtn="clickNewBtn"
          @clickReload="clickReload"
          @changeSwitch="changeSwitch" /> -->

        <!-- <SearchTopUIComp /> -->

        <!-- <Tabs /> -->

        <!-- <btns /> -->

        <!-- <datePicker /> -->

        <!-- <TimePicker /> -->

        <!-- <DateCompWithPeriodTimes propsPlaceholder="Date" :propsData="dateTimeObj"/> -->

        <!-- <DateRangeCompWithButton /> -->

        <!-- <DatePickerWithInputText value="" @onChangeDate="onChangeDateDatePicker" /> -->

        <!-- <DateRangeComp :propsStartDate="startDate" :propsEndDate="endDate" /> -->

        <!-- <ControlUI :patientData="userData" /> -->

        <!-- 미완성 -->
        <!-- <SelectBoxDrugSolo :value="drugName" @updateValue="drugNameUpdateValue"/> -->

        <!-- 대체 -->
        <!-- <SelectBoxDrug /> -->

        <!-- <SelectBoxICDCode :value="icdCode" @updateValue="icdCodeUpdateValue" /> -->

        <!-- <SelectBoxUnitWithText :items="unitType" /> -->

        <PdfMaker />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import SelectBoxForTypeList from "@/components/commonV2/ui/SelectBoxForTypeList.vue";
import SelectBirthday from "@/components/commonV2/ui/SelectBirthday.vue";
import SelectBoxHowOftenWithShot from "@/components/commonV2/ui/SelectBoxHowOftenWithShot.vue";
import SelectBoxPrimaryPhysician from "@/components/commonV2/ui/SelectBoxPrimaryPhysician.vue";
import SelectTypeWithEtc from "@/components/commonV2/ui/SelectTypeWithEtc.vue";
import SelectBoxICDCode from "@/components/commonV2/ui/SelectBoxICDCode.vue";
import SelectBoxClinic from "@/components/commonV2/ui/SelectBoxClinic.vue";
import AddressListItem from "@/components/commonV2/ui/AddressListItem.vue";
import Biometrics from "@/components/commonV2/ui/Biometrics.vue";
import CheckComps from "@/components/commonV2/ui/CheckComps.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
import DateCompWithTime from "@/components/commonV2/ui/DateCompWithTime.vue";
import DatePickerWithInputText from "@/components/commonV2/ui/DatePickerWithInputText.vue";
import InputName3Type from "@/components/commonV2/ui/InputName3Type.vue";
import DialogInputFid from "@/components/commonV2/Dialog/DialogInputFid.vue";
import DuplCheckType from "@/components/commonV2/ui/DuplCheckType.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import TopTable from "@/components/commonV2/ui/TopTable.vue";
import SearchTopUIComp from "@/components/commonV2/ui/SearchTopUIComp.vue";
import DateRangeComp from "@/components/commonV2/ui/DateRangeComp.vue";
import DateRangeCompWithButton from "@/components/commonV2/ui/DateRangeCompWithButton.vue";
import DateCompWithPeriodTimes from "@/components/commonV2/ui/DateCompWithPeriodTimes.vue";
import SelectBoxDrugSolo from "@/components/commonV2/ui/SelectBoxDrugSolo.vue";
import SelectBoxDrug from "@/components/commonV2/ui/SelectBoxDrug.vue";
import SelectBoxUnitWithText from "@/components/commonV2/ui/SelectBoxUnitWithText.vue";

import ControlUI from "@/_kimCare/patient/tabs/controlUI/ControlUI.vue";

import Tabs from "./comp/tabs.vue";
import btns from "./comp/btns.vue";
import datePicker from "./comp/datePicker.vue";
import TimePicker from "@/components/commonV2/ui/TimePicker.vue";

import PdfMaker from "./comp/PdfMaker.vue";

const formatService = require("@/utils/format.js");
export default {
  components: {
    SelectBox,
    SelectBoxForTypeList,
    SelectBirthday,
    SelectBoxHowOftenWithShot,
    SelectBoxPrimaryPhysician,
    SelectTypeWithEtc,
    SelectBoxICDCode,
    AddressListItem,
    Biometrics,
    CheckComps,
    RadioComps,
    DateCompWithTime,
    DatePickerWithInputText,
    InputName3Type,
    DialogInputFid,
    DuplCheckType,
    DataTable,
    SelectBoxClinic,
    TopTable,
    SearchTopUIComp,
    DateRangeComp,
    DateRangeCompWithButton,
    DateCompWithPeriodTimes,
    ControlUI,
    SelectBoxDrugSolo,
    SelectBoxDrug,
    SelectBoxUnitWithText,
    Tabs,
    btns,
    datePicker,
    TimePicker,
    PdfMaker,
  },
  data() {
    return {
      loading: false,
      loadingText: "Loading device list...",
      // userInfoColumns: formatService.testTableColumnsV1(),
      dates: ["2019-09-10", "2019-09-20"],
      unitType: formatService.weightList(),
      dateTimeObj: {
        date: "2024-03-14",
        startTime: "01:11:16",
        endTime: "01:11:20",
      },
      startDate: "2024-03-20",
      endDate: "",
      deviceList: [],
      userData: {
        checkedMobile: "p",
        cell: "",
        ssn: "12312132422",
        checkedSsn: "12312132422",
        duplicateMobileChecked: false,
        duplicateSSNChecked: true,
        isPhoto: "N",
        userPhotoImg: "",
        nameFull: "P- test",
        gender: "MALE",
        age: "55",
        diagnosisType: "HTM",
        serviceType: "CCM",
      },
      nameObj: {
        fname: "p",
        mname: "",
        lname: "test",
      },
      serviceTypeValue: ["CCM"],
      serviceTypeItems: [
        { value: "RPM", label: "RPM", disabled: true, color: "" },
        { value: "CCM", label: "CCM", disabled: false, color: "" },
      ],
      allergyTypeValue: ["Y"],
      allergyTypeItems: [
        { value: "N", label: "NKDA", color: "" },
        { value: "Y", label: "YES", color: "" },
      ],
      genderValue: "MALE",
      genderData: [
        { value: "MALE", label: "MALE", color: "red" },
        { value: "FEMALE", label: "FEMALE", color: "" },
        { value: "UNDEFINED", label: "UNDEFINED", color: "" },
      ],
      patientTypeValue: "MALE",
      patientTypeItems: [
        { value: "provisional", label: "Provisional Patient", color: "" },
        { value: "consent", label: "consent Patient", color: "" },
      ],
      ActiveLogValue: ["SMS", "Call"],
      ActiveLogData: [
        { value: "SMS", label: "SMS", color: "red" },
        { value: "Call", label: "Call", color: "" },
        { value: "E-mail", label: "E-mail", color: "" },
        { value: "Tele-Medicine", label: "Tele-Medicine", color: "" },
        { value: "Chart Review", label: "Chart Review", color: "" },
        { value: "6", label: "Referrals", color: "" },
      ],
      ActiveTypeValue: ["1", "2"],
      ActiveTypeData: [
        { value: "1", label: "Care coordination", color: "red" },
        { value: "2", label: "Care Plan / Management Review", color: "" },
        { value: "3", label: "Clinic Follow-up", color: "" },
        { value: "4", label: "Medication Home Coordination", color: "" },
        { value: "5", label: "Patient Education and Support", color: "" },
        { value: "6", label: "Referrals", color: "" },
        {
          value: "7",
          label: "Review of Screening / Preventive Care",
          color: "",
        },
      ],
      drugName: {
        drugName: "",
      },
      icdCode: {
        icdCode: "",
      },
      addressData: {
        zipcode: "",
        city: "",
        street: "",
        address1: "",
        address2: "",
        disabledCityAndState: true,
      },
      selectedCptCode: "",
      itemsCptCode: formatService.cptCodeOptions(),
      serviceList: formatService.serviceList(),
      diagnosisList: formatService.diagnosisList(),
      howOftenList: formatService.howOftenList(),
      timeList: formatService.timeList(),
      visitTypeItems: formatService.visitTypeItems(),
    };
  },
  mounted() {
    // setTimeout(() => this.$refs.refDialogInputFid.open(null, "SMS"), 300);
  },
  methods: {
    getStaffData() {},
    cptCode_selectEvent(value) {
      this.$emit("onChangeCptCode", value);
    },
    updateSelect(e) {
      console.log("", e);
    },
    icdCodeUpdateValue(e) {
      console.log("", e);
    },
    drugNameUpdateValue(e) {
      console.log("", e);
    },
    checkCompsChangeType(e) {
      console.log("", e);
    },
    radioCompsChangeType(e) {
      console.log("radioCompsChangeType : ", e);
    },
    onChangeDateDatePicker(e) {
      console.log("", e);
    },
    onChangeDateAndTime(e) {
      console.log("", e);
    },
  },
};
</script>
<style lang="">
</style>